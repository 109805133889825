<template>
    <div
        :class="`inputZone ${isError ? 'inputZone inputZone--error' : ''}`"
        @dragover.prevent
        @drop.prevent
        @drop="dragFile($event)"
    >
        <input
            ref="fileInput"
            id="fileInput"
            class="hidden"
            type="file"
            name="fileInput"
            :accept="fileType"
            @change="uploadFile($event)"
            multiple
        />
        <div v-if="files.length" class="inputZoneFiles">
            <div
                v-for="(file, index) in files"
                :key="file.filename"
                class="inputZoneFiles__item"
            >
                <p class="h5 colorFiord inputZoneFiles__item_text">
                    {{ file.originalname }}
                </p>
                <button
                    class="inputZoneFiles__item_remove"
                    @click="removeFile($event, file.filename)"
                >
                    <img src="@/assets/img/common/iconTrashRed36.svg" alt="remove" />
                </button>
            </div>
            <label
                v-if="files.length < maxFiles || !maxFiles"
                for="fileInput"
                class="inputZoneFiles__item"
            >
                <label for="fileInput" class="inputZoneFiles__item_add">
                    <img src="@/assets/img/common/iconPlusBlue36.svg" alt="remove" />
                </label>
            </label>
        </div>
        <label for="fileInput" v-else class="colorGray inputZone__placeholder">
            Перетащите файл(ы) или нажмите
            <span class="btn-underline">сюда</span>
        </label>
        <span class="h5 colorFiord inputZone__infoText" style="margin-bottom:8px;">
            Файлы могут содержать изображения графиков и обучающие схемы
        </span>
        <span v-if="maxFiles" class="h5 colorFiord inputZone__infoText">
            <img
                class="inputZone__infoImg"
                alt="info"
                src="@/assets/img/common/info.svg"
            />
            Максимальное количество прикрепляемых файлов - {{ maxFiles }}
        </span>

        <span v-if="isError" class="h5 error">{{ isError ? errorText : "" }}</span>
    </div>
</template>

<script>
export default {
    props: {
        fileType: String,
        receivedFiles: Array,
        maxFiles: Number,
        maxSize: Number, // в мегабайтах
        onFilesUpdate: Function,
        isError: Boolean,
        errorText: String,
    },

    data() {
        return {
            files: this.receivedFiles || [],
        };
    },

    watch: {
        receivedFiles(newValue, oldValue) {
            this.files = newValue || [];
        },
    },

    methods: {
        async uploadFile(event) {
            this.processUpload(event.target.files);
        },

        async dragFile(event) {
            this.processUpload(event.dataTransfer.files);
        },

        async processUpload(files) {
            if (this.maxFiles && this.files.length + files.length - 1 >= this.maxFiles) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: `Максимальное количество прикрепляемых файлов – ${this.maxFiles}`,
                    },
                });
            } else if (
                this.maxSize &&
                files.length &&
                [...files].filter((file) => file.size > this.maxSize * 1024 * 1024).length
            ) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: `Размер файла не должен превышать ${this.maxSize} Мб`,
                    },
                });
            } else {
                for (let i = 0; i < files.length; i++) {
                    let data = new FormData();
                    data.append("name", files[i].name);
                    data.append("file", files[i]);

                    const response = await this.axios.post("/upload", data);

                    if (response && response.data && response.data.result) {
                        response.data.files.forEach((file) => {
                            this.files.push(file);
                        });
                    } else {
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "error",
                                text:
                                    (response && response.data && response.data.error) ||
                                    "Ошибка при загрузке файла",
                            },
                        });
                    }
                }
            }

            this.onFilesUpdate(this.files);
        },

        async removeFile(event, filename) {
            this.files = this.files.filter(function(item) {
                return item.filename !== filename;
            });

            this.$refs.fileInput.value = null;

            this.onFilesUpdate(this.files);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./InputZone";
</style>
