<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/study`"
        />
        <div class="pageContent errorRequest">
            <div class="errorRequestUserInfo">
                <p>
                    Мы собрали некоторые данные о Вашем устройстве чтобы мы смогли быстрее
                    решить возникшую проблему.
                </p>
                <div class="errorRequestUserInfo__itemsContainer">
                    <div
                        class="errorRequestUserInfo__items"
                        v-for="(item, index) in userdata"
                        :key="index"
                    >
                        <p class="h4 errorRequestUserInfo__text">
                            {{ item.title }}
                        </p>
                        <p
                            :id="index"
                            class="errorRequestUserInfo__description"
                            v-if="item.description"
                        >
                            {{ item.description }}
                        </p>
                        <p
                            :class="
                                `errorRequestUserInfo__description
                                        ${
                                            item.isInternetLocked
                                                ? 'colorPomegranate'
                                                : 'colorForestGreen'
                                        }`
                            "
                            v-if="item.descriptionInternet"
                        >
                            {{ item.descriptionInternet }}
                            <Tooltip
                                class="errorRequestUserInfo__items_tooltip"
                                :tooltipIcon="require('@/assets/img/common/infoBlue.svg')"
                                text="Скорость Вашего интернет соединения влияет на качество обучения"
                            />
                        </p>
                    </div>
                </div>
            </div>
            <div class="errorRequestSendRequest">
                <div class="errorRequestSendRequest__item">
                    <p
                        v-if="sendRequestBlockContent.showForm"
                        class="h3 errorRequestSendRequest__link"
                    >
                        Перед тем, как отправить запрос об ошибке, прочтите пожалуйста ответы на часто задаваемые вопросы (ЧаВо / FAQ) по этой <a href="https://telegra.ph/CHasto-zadavaemye-voprosy-po-distancionnomu-kursu-03-22-2" target="_blank">ССЫЛКЕ</a>
                    </p>
                    <img
                        class="errorRequestSendRequest__item_img"
                        alt="replySmile"
                        :src="sendRequestBlockContent.img"
                    />
                    <p
                        v-if="this.sendRequestBlockContent.title"
                        class="h3 errorRequestSendRequest__item_title"
                    >
                        {{ sendRequestBlockContent.title }}
                    </p>
                    <p
                        v-if="this.sendRequestBlockContent.text"
                        class="colorGray errorRequestSendRequest__item_text"
                    >
                        {{ sendRequestBlockContent.text }}
                    </p>
                    <form
                        class="errorRequestSendRequest__form"
                        v-if="sendRequestBlockContent.showForm"
                        @submit.prevent="sendRequest"
                    >
                        <div
                            class="errorRequestSendRequest__selecting selecting"
                            :class="{'selecting_active': active}"
                        >
                            <div
                                class="selecting__current"
                                :class="{'selecting__current_empty': form.selectedInd === null, 'form-border-error': !form.selectValid}"
                                @click="active = !active"
                            >
                                <div class="selecting__text">
                                    {{ form.current }}
                                </div>
                                <div class="selecting__arrow"></div>
                            </div>
                            <div class="selecting__options">
                                <div
                                    class="selecting__option"
                                    v-for="(option, ind) in form.options"
                                    :key="ind"
                                    @click="form.current = option, form.selectedInd = ind, active = false, form.selectValid = true"
                                >
                                    {{ option }}
                                </div>
                            </div>
                        </div>
                        <textarea
                            class="errorRequestSendRequest__textarea"
                            :class="{'form-border-error': !form.textValid}"
                            placeholder="Напишите пожалуйста интересующий Вас вопрос"
                            @input="form.text = $event.target.value, form.textValid = true"
                        >
                        </textarea>
                        <div class="errorRequestSendRequest__file">
                        </div>
                        <InputZone
                            class="errorRequestSendRequest__file"
                            id="inputFile"
                            :fileType="'.png, .jpg, .jpeg, .gif'"
                            :maxFiles="3"
                            :maxSize="5"
                            :onFilesUpdate="(e) => updateFiles(e)"
                        />
                        <div class="errorRequestSendRequest__item_button">
                            <Button
                                v-if="!sendRequestBlockContent.showSuccessBtn"
                                title="отправить запрос"
                                btnType="submit"
                            />
                        </div>
                    </form>
                    <div class="errorRequestSendRequest__item_button">
                        <Button
                            v-if="sendRequestBlockContent.showSuccessBtn"
                            class="comparisonResultButton"
                            title="вернуться к обучению"
                            :onClick="
                                () => {
                                    $router.go(-1);
                                }
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Button from "@/views/components/Button/Button";
import Tooltip from "@/views/components/Tooltip/Tooltip";

import InputZone from "@/views/components/InputZone/InputZone";

import imgReplySmile from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSearch/replySmile.png";
import imgReplySmileHappy from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSearch/replySmileHappy.png";

export default {
    name: "errorRequest",

    components: {
        Layout,
        PageHeader,
        Button,
        Tooltip,
        InputZone
    },

    computed: {
        userdata: function() {
            return [
                {
                    title: "IP адрес:",
                    description: this.ip,
                },
                {
                    title: "Страна:",
                    description: this.country,
                },
                // {
                //     title: "Город:",
                //     description: this.city,
                // },
                {
                    title: "Браузер:",
                    description: this.browser.name + " " + this.browser.version,
                },
                {
                    title: "Операционная система:",
                    description: this.getOS(),
                },
                {
                    title: "Время UTC:",
                    description: new Date(),
                },
                {
                    title: "Поддержка cookies:",
                    description: window.navigator.cookieEnabled ? "Да" : "Нет",
                },
                {
                    title: "Поддержка Flash:",
                    description: this.hasFlash,
                },
                {
                    title: "Блокировщик рекламы:",
                    description: "Нет",
                },
                {
                    title: "Useragent:",
                    description: window.navigator.userAgent || "",
                },
                {
                    title: "Скорость интернета:",
                    descriptionInternet: this.downlink + " Mbps",
                    isInternetLocked: this.downlink < 10,
                },
                {
                    title: "Поддержка JavaScript:",
                    description: "Да",
                },
            ];
        },
    },

    async beforeMount() {
        try {
            var fo = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
            if (fo) {
                this.hasFlash = "Да";
            }
        } catch (e) {
            if (
                navigator.mimeTypes &&
                navigator.mimeTypes["application/x-shockwave-flash"] != undefined &&
                navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin
            ) {
                this.hasFlash = "Да";
            }
        }

        let adblockScript = document.createElement("script");
        adblockScript.setAttribute(
            "src",
            "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        );
        adblockScript.setAttribute("async", "true");
        adblockScript.setAttribute(
            "onerror",
            '{ document.getElementById("8").textContent = "Да" }'
        );
        document.head.appendChild(adblockScript);

        this.axios.get("https://ipinfo.io/json").then((response) => {
            this.ip = response.data.ip;
            this.country = response.data.country;
            // this.city = response.data.city;
        });

        this.measureConnectionSpeed();
    },

    data() {
        return {
            title: "Запрос об ошибке",

            items: [
                {
                    text: "Главная",
                    to: "/",
                },
                {
                    text: "Запрос об ошибке",
                    active: true,
                },
            ],

            ip: "",
            country: "",
            city: "",
            browser: this.getBrowser(),
            hasFlash: "Нет",
            downlink: 0,

            sendRequestBlockContent: {
                img: imgReplySmile,
                title: "Возникли проблемы с воспроизведением видео?",
                text: "",
                showForm: true,
                showSuccessBtn: false,
            },

            active: false,
            form: {
                current: 'Выберите подходящую категорию для Вашей ошибки',
                options: [
                    'Не работает видео',
                    'Плохо работает личный кабинет',
                    'Другой технический вопрос',
                    'Организационный вопрос'
                ],
                selectedInd: null,
                text: '',
                selectValid: true,
                textValid: true,
                files: []
            }
        };
    },

    methods: {
        updateFiles(files) {
            this.form.files = files
        },
        validation() {
            let isValid = true

            if (this.form.selectedInd === null) {
                isValid = false
                this.form.selectValid = false
            }

            if (!this.form.text) {
                isValid = false
                this.form.textValid = false
            }

            return isValid
        },
        async sendRequest() {
            if (!this.validation()) return
            
            const data = {
                // ip: this.userdata[0].description,
                // country: this.userdata[1].description,
                // city: this.userdata[2].description,
                // browser: this.userdata[3].description,
                // os: this.userdata[4].description,
                // utc: this.userdata[5].description,
                // cookies: this.userdata[6].description,
                // flash: this.userdata[7].description,
                // adblock: this.userdata[8].description,
                // useragent: this.userdata[9].description,
                // downlink: this.userdata[10].description,
                // js: this.userdata[11].description,
                ip: this.userdata[0].description,
                country: this.userdata[1].description,
                browser: this.userdata[2].description,
                os: this.userdata[3].description,
                utc: this.userdata[4].description,
                cookies: this.userdata[5].description,
                flash: this.userdata[6].description,
                adblock: this.userdata[7].description,
                useragent: this.userdata[8].description,
                downlink: this.userdata[9].descriptionInternet,
                js: this.userdata[10].description,
                category: this.form.options[this.form.selectedInd],
                text: this.form.text,
                images: this.form.files.map(file => 'https://api.edu.gerchik.com' + file.url)
            };

            const getResult = await this.axios.post("/usercheck/bug_report", data);

            if (getResult && getResult.data && getResult.data.result) {
                this.sendRequestBlockContent.img = imgReplySmileHappy;
                this.sendRequestBlockContent.title = "Ваш запрос отправлен!";
                this.sendRequestBlockContent.text =
                    "Мы уже работаем над проблемой и сообщим Вам о результатах.";
                this.sendRequestBlockContent.showForm = false;
                this.sendRequestBlockContent.showSuccessBtn = true;
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            "Ошибка при отправке запроса. Напишите нам на почту чтобы мы могли выяснить проблему.",
                    },
                });
            }
        },

        getBrowser() {
            var ua = navigator.userAgent,
                tem,
                M =
                    ua.match(
                        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
                    ) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: "IE", version: tem[1] || "" };
            }
            if (M[1] === "Chrome") {
                tem = ua.match(/\bOPR|Edge\/(\d+)/);
                if (tem != null) {
                    return { name: "Opera", version: tem[1] };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                M.splice(1, 1, tem[1]);
            }
            return {
                name: M[0],
                version: M[1],
            };
        },

        getOS() {
            var OSName = "Unknown";
            if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
                OSName = "Windows 10";
            if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
                OSName = "Windows 8.1";
            if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
                OSName = "Windows 8";
            if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
                OSName = "Windows 7";
            if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
                OSName = "Windows Vista";
            if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
                OSName = "Windows XP";
            if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
                OSName = "Windows 2000";
            if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
            if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
            if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

            return OSName;
        },

        async measureConnectionSpeed() {
            const imageAddr = 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Brandenburger_Tor_abends.jpg';
            const downloadSize = 2707459; // this must match with the image above

            let startTime, endTime;

            startTime = (new Date()).getTime();
            const cacheBuster = '?nnn=' + startTime;

            const download = new Image();
            download.src = imageAddr + cacheBuster;

            // this returns when the image is finished downloading
            await download.decode();
            endTime = (new Date()).getTime();
            const duration = (endTime - startTime) / 1000;
            const bitsLoaded = downloadSize * 8;
            const speedBps = (bitsLoaded / duration).toFixed(2);
            const speedKbps = (speedBps / 1024).toFixed(2);
            const speedMbps = (speedKbps / 1024).toFixed(2);

            this.downlink = Number(speedMbps);
        }
    },
};
</script>

<style lang="scss">
@import "./errorRequest.scss";
</style>
